import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Container, Row, Col, Card, Placeholder, Button,Dropdown,Pagination   } from 'react-bootstrap';
// import { generateCustomPlaceholderURL,generatePhotoPlaceholderURL  } from 'react-placeholder-image';
import { getReadableStringOfDate } from '../../utils/generalHelper';
//Markdown-Parser
import ReactMarkdown from 'react-markdown';
const axios = require('axios');
const qs = require('qs');

//Load Styles
import styles from '../../styles/components/blog/Blogs.module.scss'
//i18n
import { useTranslation } from 'react-i18next';

// const dummyBlogEntries = [
//   {
//     id : 1,
//     img: generatePhotoPlaceholderURL(300, 300),
//     img_height: 300,
//     attributes:{
//       title: "Title 1",
//       subtitle: "subTitle 1",
//       description: "Description 1",
//       slug:"this-is-post-number-1"
//     }
//   },
//   {
//     id : 2,
//     img: generatePhotoPlaceholderURL(300, 300),
//     img_height: 300,
//     attributes:{
//       title: "Title 2",
//       subtitle: "subTitle 2",
//       description: "Description 2",
//       slug:"this-is-post-number-2"
//     }
//   },
//   {
//     id : 3,
//     img: generatePhotoPlaceholderURL(300, 300),
//     img_height: 300,
//     attributes:{
//       title: "Title 3",
//       subtitle: "subTitle 3",
//       description: "Description 3",
//       slug:"this-is-post-number-3"
//     }
//   },
//   {
//     id : 4,
//     img: generatePhotoPlaceholderURL(300, 300),
//     img_height: 300,
//     attributes:{
//       title: "Title 4",
//       subtitle: "subTitle 4",
//       description: "Description 4",
//       slug:"this-is-post-number-4"
//     }
//   },
//   {
//     id : 5,
//     img: generatePhotoPlaceholderURL(300, 300),
//     img_height: 300,
//     attributes:{
//       title: "Title 5",
//       subtitle: "subTitle 5",
//       description: "Description 5",
//       slug:"this-is-post-number-5"
//     }
//   },
//   {
//     id : 6,
//     img: generatePhotoPlaceholderURL(300, 300),
//     img_height: 300,
//     attributes:{
//       title: "Title 6",
//       subtitle: "subTitle 6",
//       description: "Description 6",
//       slug:"this-is-post-number-6"
//     }
//   }
// ];


function Posts(){
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedBlogCategory, setSelectedBlogCategory] = useState(undefined);
  const [oldSelectedBlogCategory, setOldSelectedBlogCategory] = useState(undefined);
  const [blogCategories, setBlogCategories] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [pageCount, setPageCount] = useState(1);
  const [totalEntries, setTotalEntries] = useState(1);
  const [paginationItems, setPaginationItems] = useState([]);
  const [selectedSortingMechanism, setSelectedSortingMechanism] = useState("date:desc");
  const [selectedSortingMechanismText, setSelectedSortingMechanismText] = useState( t('page_blog_selected_sorting_newest_first'));

  const fetchData = async () => {
    
    try {
      let response = {};
      if(selectedBlogCategory !== undefined && selectedBlogCategory !== oldSelectedBlogCategory)
      {
        response = await fetch(`/api/backend/blog/blog?locale=${i18n.language}&category=${selectedBlogCategory}&sorting=${selectedSortingMechanism}&${`paginationPage=1&paginationPageSize=${pageSize}`}`, {
          headers: {
            Accept: "application/json",
          },
        });
        setSelectedPage(1);
      }
      else if(selectedBlogCategory !== undefined)
      {
        response = await fetch(`/api/backend/blog/blog?locale=${i18n.language}&category=${selectedBlogCategory}&sorting=${selectedSortingMechanism}&${`paginationPage=${selectedPage}&paginationPageSize=${pageSize}`}`, {
          headers: {
            Accept: "application/json",
          },
        });
      }
      else if(selectedBlogCategory === undefined && selectedBlogCategory !== oldSelectedBlogCategory)
      {
        response = await fetch(`/api/backend/blog/blog?locale=${i18n.language}&sorting=${selectedSortingMechanism}&${`paginationPage=1&paginationPageSize=${pageSize}`}`, {
          headers: {
            Accept: "application/json",
          },
        });
        setSelectedPage(1);
      }
      else
      {
        response = await fetch(`/api/backend/blog/blog?locale=${i18n.language}&sorting=${selectedSortingMechanism}&${`paginationPage=${selectedPage}&paginationPageSize=${pageSize}`}`, {
          headers: {
            Accept: "application/json",
          },
        });
      }

    
      const fetchedData = await response.json();      
      setData(fetchedData.data);    
      setOldSelectedBlogCategory(selectedBlogCategory);
      // setSelectedPage(fetchedData.meta.pagination.page);
      // setPageSize(fetchedData.meta.pagination.pageSize);
      setPageCount(fetchedData.meta.pagination.pageCount);
      setTotalEntries(fetchedData.meta.pagination.total);
      setPaginationItems(calculatePagination(fetchedData.meta.pagination.pageCount));
      
    } catch (error) {

      console.log('[Error - Posts Component] - ' + error);
      
    }
    finally{
      setIsLoading(false)
    }
  };

  const fetchBlogCategoryData = async () => {
    
    try {
      let response = {};
     
        response = await fetch(`/api/backend/blog/blogCategories?locale=${i18n.language}`, {
          headers: {
            Accept: "application/json",
          },
        });
    
      const fetchedData = await response.json();      
      setBlogCategories(fetchedData.data);
      
    } catch (error) {

      console.log('[Error - Posts Component - fetchBlogCategoryData] - ' + error);
      
    }
    finally{
      setIsLoading(false)
    }
  };

  function calculatePagination(count){

    let active = selectedPage;
    let items = [];
    for (let number = 1; number <= count; number++) {
      items.push(
        <Pagination.Item 
        className={styles['pagination-item']}
        key={number} 
        active={number === active}
        onClick={()=> setSelectedPage(number)}>
          {number}
        </Pagination.Item>,
      );
    }

    return items;
  }

  useEffect(() => {
    //Simulate long loading in miliseconds
    //For Production Usage set to Minimum! 
    //  setTimeout(() => setIsLoading(false), 10);
    // debugger;
      async function fetchDataFromNextAPI() {           
        await fetchData(); 
        setIsLoading(false);            
      }      
      
      fetchDataFromNextAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[i18n.language,selectedBlogCategory,selectedSortingMechanism,selectedPage])

  useEffect(() => {
    //Simulate long loading in miliseconds
    //For Production Usage set to Minimum! 
    // debugger;
    //  setTimeout(() => setIsLoading(true), 10);

      async function fetchDataFromNextAPI() {           
        await fetchBlogCategoryData();             
      }      
      
      fetchDataFromNextAPI();
      // setIsLoading(false);
      //  setTimeout(() => setIsLoading(false), 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[i18n.language])



  if (isLoading===true) return "loading...";

  return(
     
  <React.Fragment>   
    <Container>
      <Row>

      
      <Col  xs={'auto'} sm={'auto'} md={9}  lg={9}  xl={9} >
      <Button
      className={`${styles["categories-quick-filter-button"]}`}
      onClick={() => setSelectedBlogCategory(undefined)}>Alle</Button>
      {
        blogCategories.map((blogCategory) => (          
          <Button
          className={`${styles["categories-quick-filter-button"]}`}
          key={blogCategory.id.toString()}
          onClick={() => setSelectedBlogCategory(`${blogCategory.attributes.name}`)}>{blogCategory.attributes.name}</Button>
        ))
      }
      </Col>
      <Col  xs={'auto'} sm={'auto'} md={3}  lg={3}  xl={3}
      align="end" >
        <Dropdown align="end">
          <Dropdown.Toggle
      
          className={`${styles["blog-sorting-button"]}`}
          id="dropdown-basic">
            {selectedSortingMechanismText}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => {
               setSelectedSortingMechanism('date:desc')
               let a = t('page_blog_selected_sorting_newest_first');
              //  setSelectedSortingMechanismText('date:desc')
               setSelectedSortingMechanismText(a)
               }}>Neueste zuerst</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              setSelectedSortingMechanism('date:asc')
              let a = t('page_blog_selected_sorting_oldest_first');
              // setSelectedSortingMechanismText('date:asc')
              setSelectedSortingMechanismText(a)
              }}>Älteste zuerst</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      </Row>
    </Container>
    <Container className="pt-4 pb-5">
      {/* Set Number of Posts Displayed here: */}
        <Row xs={1} md={2} lg={2} xl={3} 
        className="g-5 p-1"
        >
          {           
            isLoading === false ?

            //  dummyBlogEntries.map((blogEntry) => (
            data.map((blogEntry) => (
              <div key={blogEntry.id.toString()}>
                <Col >
                      <div className="d-flex justify-content-around">                
                      <Card >
                        {/* <Card.Header>{`${getReadableStringOfDate(blogEntry.attributes.date,'dd.LLLL.yyyy')} | by ${blogEntry.attributes.author}`}</Card.Header> */}
                        <div className={`${styles["post-date"]}`} style={{
                          backgroundColor: blogEntry.attributes.date_box_background_color,
                          color:blogEntry.attributes.date_box_text_color
                          }}>
                          <i className="fa fa-calendar"></i>
                          <strong className={`${styles["post-date-strong"]}`}>{`${getReadableStringOfDate(blogEntry.attributes.date,'dd')}`}</strong>
                          <span>{`${getReadableStringOfDate(blogEntry.attributes.date,'LLL')}`}</span>
                        </div>
                        <div className={`${styles.hover03} ${styles.column}`}>
                          <div>
                            <figure className={styles.figure}>
                              {/* <Card.Img className={styles.blogEntryImage }
                               variant="top"
                               alt={`${blogEntry.attributes.slug}`}
                               src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${blogEntry.attributes.preview_image.data.attributes.url}`}
                              //  height={blogEntry.img_height}
                               height={'280px'}
                               width={'300px'}
                               /> */}
                               {/* <CardMedia className={styles.blogEntryImage}
                               title={`${blogEntry.attributes.slug}`}> */}
                                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                  <Image src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${blogEntry.attributes.preview_image.data.attributes.url}`}
                                   alt={`${blogEntry.attributes.slug}`}
                                  layout="fill"
                                  objectFit="cover" />
                                </div>
                              {/* </CardMedia> */}
                             
                            </figure>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Title>{blogEntry.attributes.title}</Card.Title>
                          <Card.Subtitle>{blogEntry.attributes.subtitle}</Card.Subtitle>
                          <Card.Text>                  
                          {/* <ReactMarkdown>{blogEntry.attributes.content.substring(0, 200) + '...'}</ReactMarkdown> */}
                          {blogEntry.attributes.content.substring(0, 200) + '...'}
                          </Card.Text>
                          
                          {/* <Button variant="primary" href={`/posts/${blogEntry.attributes.slug}`}>{blogEntry.attributes.preview_button_text}</Button> */}
                          <Button
                          className={`${styles["gotoblog-detail-button"]}`}
                          variant="primary"
                          href={`/posts/${blogEntry.attributes.slug}`}>{blogEntry.attributes.preview_button_text}</Button>

                        </Card.Body>
                      </Card>
                    </div>
                </Col>
              </div>
          ))
          :
          // dummyBlogEntries.map((idx) => (
          //   <div key={idx.id}>
          //   <Col>
          //   <Card style={{ width: '18rem' }}>
          //   <Card.Img variant="top" src={generateCustomPlaceholderURL(400, 400)} />
          //   <Card.Body>
          //     <Placeholder as={Card.Title} animation="glow">
          //       <Placeholder xs={6} />
          //     </Placeholder>
          //     <Placeholder as={Card.Text} animation="wave">
          //       <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
          //       <Placeholder xs={6} /> <Placeholder xs={8} />
          //     </Placeholder>
          //     <Placeholder.Button variant="primary" xs={6} 
          //     // className=""
          //     />
          //   </Card.Body>
          // </Card>
          // </Col>
          // </div>
          // ))
          <></>
        }
        </Row>
    </Container>
    <Container>
      <Row className='justify-content-center'>
        <Col></Col>
        <Col xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
            <Pagination>{paginationItems}</Pagination>
        </Col>
        <Col></Col>

      </Row>
    </Container>

</React.Fragment>
    
    )
    }
    
    export default Posts;


// Server Side Functions
    export async function getAllSlugs(){
      let paths = [];
    //  const slug1 = '/posts/deutscher';
    //  const slug2 = '/posts/englisher';
    //   paths.push(slug1);
    //   paths.push(slug2);

       
      try {
        
        const config = {
          headers: { Authorization: `Bearer ${process.env.BACKEND_API_KEY}` }
        };
        console.log(`Trying to load all slugs with: Bearer ${process.env.BACKEND_API_KEY} and n\\ ${process.env.BACKEND_API_URL}/api/blogs?fields=slug&locale=all`)
        const url =`${process.env.BACKEND_API_URL}/api/blogs?fields=slug&locale=all`;   
        
        const result = await axios.get(url,config);

        const applicationSettings = result;

        const test = Object.values(applicationSettings.data.data).map(x => '/posts/'+x.attributes.slug.toString());


        // let resultStringArray = [];

        // for(let i = 0;i<test.length;i++)
        // {
        //   resultStringArray.push(test[i].toString());
        // }

       
        console.log(test);
        // console.log(resultStringArray);
        paths = test;

      } 
      catch (error) {

        console.log(`[getPosts Endpoint @ NextApi] Error occurred when calling Backend...`);
        throw error;
      }


      return paths;


    }


    export async function getPostDetail(slug,language='all'){

      try {
        

        const query = qs.stringify({ 
          filters:  {
            slug: {
              $eq: slug,
            },
          },
          populate: '*',
          fields: '*',
          publicationState: 'live',
          locale: language         
        }, 
        {
          encodeValuesOnly: true, // prettify url
        });
        const config = {
          headers: { Authorization: `Bearer ${process.env.BACKEND_API_KEY}` }
        };

        const url =`${process.env.BACKEND_API_URL}/api/blogs?${query}`;   
        
        const result = await axios.get(url,config);

        const applicationSettings = result;

        return applicationSettings.data.data;

      } 
      catch (error) {

        console.log(`[getPosts Endpoint @ NextApi] Error occurred when calling Backend...`);
        throw error;
      }

    }