const { DateTime } = require("luxon");

function getReadableStringOfDate(dateTime, format){
    try 
    {
        //see Examples of how formatting can be done here: https://moment.github.io/luxon/#/formatting?id=toformat
        const currentDate = DateTime.fromISO(dateTime, {zone: "Europe/Vienna",locale: "de-AT" });       
        
        let formattedDate = currentDate.toFormat(format);
        
      return formattedDate;
  
    } 
    catch (error) {
       
      throw error;
    }
    
  }

module.exports = {
  getReadableStringOfDate,

}